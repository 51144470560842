<script lang="ts">
  import { omit } from 'shared/src/utils/omit';
  import { createEventDispatcher } from 'svelte';
  import { twMerge } from 'tailwind-merge';
  import type { ButtonColor } from './ButtonDefinitions';

  interface $$Props extends Partial<HTMLButtonElement & HTMLAnchorElement> {
    class?: string;
    color?: ButtonColor;
    disabled?: boolean;
    as?: 'button' | 'a';
  }

  interface $$Events extends Omit<Partial<HTMLButtonElement & HTMLAnchorElement>, 'click'> {
    disabledClick: CustomEvent<Event>;
    click: CustomEvent<Event>;
  }

  let clazz: string = '';
  export let type: string = '';
  export let disabled: boolean = false;
  export { clazz as class };
  export let color: ButtonColor | undefined = undefined;
  export let as: 'button' | 'a' = 'button';

  const dispatch = createEventDispatcher<{ click: Event; disabledClick: Event }>();

  function click(event: Event) {
    return disabled ? dispatch('disabledClick', event) : dispatch('click', event);
  }

  let colorClass: string = '';

  const sharedClasses = 'text-white transition-colors u-button';

  const enabledShadow = 'shadow-md shadow-gray-300 [text-shadow:0_0_2px_rgba(0,0,0,0.3)]';

  const disabledCursor = 'cursor-default';

  $: {
    switch (color) {
      case 'green':
        colorClass = disabled
          ? `${sharedClasses} ${disabledCursor} bg-green-200 text-white high-contrast:bg-green-500`
          : `${sharedClasses} ${enabledShadow} bg-green-500 text-white high-contrast:bg-green-700 hover:bg-green-600 high-contrast:hover:bg-green-600`;
        break;
      case 'red':
        colorClass = disabled
          ? `${sharedClasses} ${disabledCursor} bg-red-50 high-contrast:bg-red-200 text-white`
          : `${sharedClasses} ${enabledShadow} bg-red-500 text-white high-contrast:bg-red-600 hover:bg-red-600 high-contrast:hover:bg-red-500`;
        break;
      case 'blue':
        colorClass = disabled
          ? `${sharedClasses} ${disabledCursor} bg-blue-200 high-contrast:bg-blue-300 text-white`
          : `${sharedClasses} ${enabledShadow} bg-blue-500 text-white high-contrast:bg-blue-700 hover:bg-blue-600 high-contrast:hover:bg-blue-600`;
        break;
      case 'cyan':
        colorClass = disabled
          ? `${sharedClasses} ${disabledCursor} bg-cyan-200 high-contrast:bg-cyan-400 text-white`
          : `${sharedClasses} ${enabledShadow} bg-cyan-500 text-white high-contrast:bg-cyan-700 hover:bg-cyan-600 high-contrast:hover:bg-cyan-600`;
        break;
      case 'gray':
        colorClass = disabled
          ? `${sharedClasses} ${disabledCursor} bg-gray-200 high-contrast:bg-gray-300 text-white`
          : `${sharedClasses} ${enabledShadow} bg-gray-400 text-white high-contrast:bg-gray-500 hover:bg-gray-500 high-contrast:hover:bg-gray-400`;
        break;
      case 'none':
        colorClass = '';
        break;
      case 'orange':
      default:
        colorClass = disabled
          ? `${sharedClasses} ${disabledCursor} bg-orange-200 high-contrast:bg-orange-300 text-white`
          : `${sharedClasses} ${enabledShadow} bg-orange-500 text-white high-contrast:bg-orange-800 hover:bg-orange-600 high-contrast:hover:bg-orange-900`;
        break;
    }
  }
</script>

<svelte:element
  this={as}
  {...omit($$props, 'type', 'disabled')}
  {type}
  on:click={click}
  on:change
  on:abort
  on:blur
  on:focus
  on:input
  on:invalid
  on:keydown
  on:keypress
  on:keyup
  on:mousedown
  on:mouseenter
  on:mouseleave
  on:mousemove
  on:mouseout
  on:mouseover
  on:mouseup
  aria-disabled={disabled}
  class={twMerge(`
    ${colorClass}
    inline-block
    text-center
    text-lg
    font-normal
    rounded
    py-1.5
    px-2
    placeholder-gray-200
    ${clazz}`)}
  class:disabled><slot /></svelte:element
>
